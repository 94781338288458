import React from "react";
import "./styles.css";

export default class App extends React.Component {
    render() {
        return (
            <h1>Hello world</h1>
        );
    }
}
